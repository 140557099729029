<template>
  <!-- 电子发票首页 -->
  <div>
  <van-nav-bar
      class="backBox"
      title="发票服务"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />

  <div class="page">

    <div class="mainWrap">
      <div class="box_item">
        <div class="list">
          <!-- <span class="title">选择类型&nbsp;开具发票</span> -->
          <span class="title">开具发票</span>
          <div class="menu">
            <div class="img_box" @click="openBillForPark">
              <img
                src="../../../assets/img/tingchekaipiao@2x.png"

              />
              <span class="menu_tit">停车开票</span>
            </div>
            <!-- <div class="img_box" @click="openBillForMonth">
              <img
                src="../../../assets/img/yuekakaipiao@2x.png"

              />
              <span class="menu_tit">月卡开票</span>
            </div> -->
          </div>
        </div>
        <div class="list">
            <span class="title">发票服务</span>
          <div class="menu">
            <div class="img_box" @click="openBillRecord">
              <img
                src="../../../assets/img/kaipiaojilu@2x.png"

              />
              <span class="menu_tit">开票记录</span>
            </div>
            <!-- <div class="img_box" @click="openBillTit">
              <img
                src="../../../assets/img/fapiaotaitou@2x.png"

              />
              <span class="menu_tit">发票抬头</span>
            </div> -->
            <!-- <div class="img_box" @click="openQuestion">
              <img
                src="../../../assets/img/changjianwenti@2x.png"

              />
              <span class="menu_tit">常见问题</span>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>
</template>
<script>
export default {
  data () {
    return {

    }
  },
  mounted () {

  },
  computed: {

  },
  methods: {
    openBillForPark () {
      this.$router.push('/monthParkingInvoice?type=2')
    },
    openBillForMonth () {
      this.$router.push('/monthParkingInvoice?type=1')
    },
    openBillRecord () {
      this.$router.push('/invoiceRecord')
    },
    openBillTit () {

    },
    openQuestion () {
      this.$router.push('/invoiceProblem')
    },
    onClickLeft () {
      // this.$router.go(-1)
      this.$router.replace({ path: '/index', query: { sid: this.sid, channel_path: this.channelPath } })
    }

  }
}
</script>
<style lang="scss" scoped>
.page {
}
.mainWrap {
  display: flex;
  flex-direction: column;

  .box_item {
    height: 2.32rem;
    display: flex;
    flex-direction: column;
    padding: 0.16rem;
    .list {
      background-color: rgba(255, 255, 255, 1);
      border-radius: 0.02rem;
      position: relative;
      /* width: 686rpx; */
      height: 1.58rem;
      margin-bottom: 0.16rem;
      display: flex;
      flex-direction: column;
      padding: 0.44rem 1.96rem 0.15rem 0.16rem;
      .title {
        position: absolute;
        left: 0.15rem;
        top: 0.1rem;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 0.14rem;
        font-family: PingFangSC-Regular;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        line-height: 0.2rem;
      }
      .menu {
        width: 1.31rem;
        height: 0.49rem;
        flex-direction: row;
        display: flex;
        .img_box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          // margin-left: 0.08rem;
          img {
            width: 0.22rem;
            height: 0.21rem;
            // margin: 0 0.21rem 0 0.13rem;
          }
          .menu_tit {
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 0.14rem;
            font-family: PingFangSC-Regular;
            font-weight: normal;
            text-align: left;
            white-space: nowrap;
            line-height: 0.2rem;
            margin-top: 0.08rem;
          }
        }
      }
    }
  }
}
</style>
